<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="isCreated ? $t('peripheralList.addPeripheral') : $t('peripheralList.editPeripheral')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-deviceType"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
      <el-form-item :label="$t('deviceList.peripheralName')" prop="peripheralName">
        <el-input v-model.trim="form.peripheralName" />
      </el-form-item>

      <el-form-item :label="$t('deviceList.peripheralType')" prop="peripheralType">
        <el-select
          v-model="form.peripheralType"
          :placeholder="$t('peripheralList.selectPeripheralType')"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="UID" prop="uid">
        <el-input v-model.trim="form.uid" />
      </el-form-item>

      <el-form-item label="PID" prop="pid">
        <el-input v-model.trim="form.pid" />
      </el-form-item>

      <el-form-item
        v-for="(item, index) in subAttribute"
        :key="item.subAttributeName"
        :label="item.subAttributeName"
      >
        <div class="iconItemWrap">
          <el-input v-model.trim="item.subAttributeValue" />
          <i @click="subAttribute.splice(index, 1)" class="el-icon-remove-outline"></i>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="addNewAttribute">{{
        $t('peripheralList.newAttribute')
      }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      loading: false,
      form: {
        peripheralName: '',
        peripheralType: 'printer',
        uid: '',
        pid: ''
      },
      rules: {
        peripheralName: [
          { required: true, message: this.$t('peripheralList.enterPeripheralName') }
        ],
        uid: [{ required: true, message: this.$t('peripheralList.enterUID') }],
        pid: [{ required: true, message: this.$t('peripheralList.enterPID') }]
      },
      subAttribute: [],
      options: [
        {
          label: this.$t('deviceList.printer'),
          value: 'printer'
        },
        {
          label: this.$t('deviceList.scanningDevice'),
          value: 'scanningEquipment'
        },
        {
          label: this.$t('deviceList.camera'),
          value: 'camera'
        },
        {
          label: this.$t('deviceList.electronicScales'),
          value: 'electronicScales'
        }
      ]
    }
  },

  created() {
    if (!this.isCreated) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.currentItem[key]
        this.form.uid = this.currentItem.uID
        this.form.pid = this.currentItem.pID
      })

      this.subAttribute = JSON.parse(this.currentItem.subAttribute || '')
    }
  },
  computed: {
    currentSubAttributeNames() {
      return this.subAttribute.map(item => item.subAttributeName)
    }
  },
  methods: {
    addNewAttribute() {
      let enterAttributeName = this.$t('peripheralList.enterAttributeName')
      this.$prompt(enterAttributeName, this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        inputPattern: /\S+/,
        closeOnClickModal: false,
        inputErrorMessage: enterAttributeName,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            if (this.currentSubAttributeNames.includes(instance.inputValue)) {
              this.$message.warning(this.$t('peripheralList.attributeNameAlreadyExists'))
              return
            }

            this.subAttribute.push({
              subAttributeName: instance.inputValue,
              subAttributeValue: ''
            })
            done()
          } else {
            done()
          }
        }
      })
    },
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          let params = { ...this.form }

          if (!this.isCreated) {
            params.id = this.currentItem.id
          }

          const subAttribute = this.subAttribute.filter(_ => _.subAttributeValue)

          params.subAttribute = JSON.stringify(subAttribute)

          try {
            this.isCreated
              ? await this.$api.deviceManagement.configurationSave(params)
              : await this.$api.deviceManagement.configurationUpdate(params)

            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-deviceType {
  .el-select {
    width: 100%;
  }

  .el-upload-list__item-name {
    cursor: default;
    &:hover,
    &:focus {
      color: inherit !important;
    }
  }

  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #ef2b23;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .iconItemWrap {
    display: flex;
    align-items: center;
    .el-icon-remove-outline {
      font-size: 20px;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}
</style>
