<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="
      isCreated ? this.$t('deviceRoom.newIssueEquipment') : this.$t('deviceRoom.editIssueEquipment')
    "
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-device"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="120px">
      <el-form-item :label="$t('deviceRoom.issueCustomer')" prop="spId">
        <el-select v-model="form.spId" :placeholder="$t('deviceModal.selectCustomer')">
          <el-option
            v-for="item in serviceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('deviceList.deviceType')" prop="modelId">
        <el-select v-model="form.modelId" :placeholder="$t('deviceModal.selectDeviceModel')">
          <el-option
            v-for="item in modelList"
            :key="item.value"
            :label="`${item.label}`"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('deviceList.sn')" :prop="isCreated ? 'sn' : ''">
        <el-input
          :disabled="!isCreated"
          v-model.trim="form.sn"
          :placeholder="$t('deviceModal.pleaseEnterSNNumber')"
        />
      </el-form-item>

      <el-form-item :label="$t('deviceRoom.operatingSystem')" prop="systemType">
        <el-select v-model="form.systemType" :placeholder="$t('deviceRoom.selectOperatingSystem')">
          <el-option
            v-for="item in systemList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('deviceRoom.screen')" prop="systemType">
        <el-select v-model="form.screenType" :placeholder="$t('deviceRoom.selectScreen')">
          <el-option
            v-for="item in screenList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('deviceList.printer')">
        <el-select v-model="form.printerId" :placeholder="$t('deviceRoom.selectPrinter')" clearable>
          <el-option
            v-for="item in printerList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('deviceList.scanningDevice')">
        <el-select v-model="form.scanningEquipmentId" :placeholder="$t('deviceRoom.selectScanningDevice')" clearable>
          <el-option
            v-for="item in scanningEquipmentList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('deviceList.camera')">
        <el-select v-model="form.cameraId" :placeholder="$t('deviceRoom.selectCamera')" clearable>
          <el-option
            v-for="item in cameraList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
	  
	  <el-form-item :label="$t('deviceList.electronicScales')">
	    <el-select v-model="form.electronicScalesId" :placeholder="$t('deviceRoom.selectElectronicScales')" clearable>
	      <el-option
	        v-for="item in electronicScalesList"
	        :key="item.value"
	        :label="item.label"
	        :value="item.value"
	      />
	    </el-select>
	  </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  props: ['serviceList', 'modelList', 'printerList', 'scanningEquipmentList', 'cameraList', 'electronicScalesList'],
  data() {
    return {
      loading: false,
      systemList: [
        {
          label: this.$t('deviceRoom.android'),
          value: 1
        },
        {
          label: this.$t('deviceRoom.windows'),
          value: 2
        }
      ],
      screenList: [
        {
          label: this.$t('deviceRoom.singleScreen'),
          value: 1
        },
        {
          label: this.$t('deviceRoom.dualScreen'),
          value: 2
        }
      ],
      form: {
        spId: '',
        modelId: '',
        systemType: '',
        screenType: '',
        sn: '',
        printerId: '',
        scanningEquipmentId: '',
        cameraId: '',
		electronicScalesId: ''
      },
      rules: {
        spId: [{ required: true, message: this.$t('deviceModal.selectCustomer') }],
        systemType: [{ required: true, message: this.$t('deviceRoom.selectOperatingSystem') }],
        screenType: [{ required: true, message: this.$t('deviceRoom.selectScreen') }],
        modelId: [{ required: true, message: this.$t('deviceModal.selectDeviceModel') }],
        sn: [{ required: true, message: this.$t('deviceModal.pleaseEnterSNNumber') }]
      }
    }
  },
  async created() {
    if (!this.isCreated) {
      this.form.spId = this.currentItem.spId
      this.form.modelId = this.currentItem.modelId
      this.form.sn = this.currentItem.sn
      this.form.printerId = String(this.currentItem.printerId || '')
      this.form.scanningEquipmentId = String(this.currentItem.scanningEquipmentId || '')
      this.form.cameraId = String(this.currentItem.cameraId || '')
      this.form.systemType = Number(this.currentItem.systemType) || ''
      this.form.screenType = Number(this.currentItem.screenType) || ''
	  this.form.electronicScalesId = String(this.currentItem.electronicScalesId || '')
    }
  },
  methods: {
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (!this.isCreated) {
            data.sn = this.currentItem.sn
            data.id = this.currentItem.id
          }
          this.loading = true
          try {
            this.isCreated
              ? await this.$api.deviceManagement.deviceRoomAdd(data)
              : await this.$api.deviceManagement.deviceRoomUpdate(data)
            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
