<template>
  <page>
    <div slot="headerLeft">
      <el-form inline>
        <el-form-item :label="$t('deviceList.sn')">
          <el-input
            :placeholder="$t('deviceList.sn')"
            v-model.trim="params.sn"
            @keyup.enter.native="replaceData"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('deviceList.deviceType')">
          <el-select collapse-tags clearable v-model="params.modelId" filterable>
            <el-option
              v-for="item in modelList"
              :key="item.value"
              :label="`${item.label}（${item.signatureFileName}）`"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('serviceManagement.customerName')">
          <SelectCustomer :value.sync="params.spId" :name.sync="params.name" />
        </el-form-item>
        <el-form-item :label="$t('deviceRoom.deliveryTime')">
          <el-date-picker
            v-model="params.time"
            type="daterange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :range-separator="$t('to')"
            :start-placeholder="$t('startDate')"
            :end-placeholder="$t('endDate')"
            :default-time="['00:00:00', '23:59:59']"
          />
        </el-form-item>

        <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
        <el-button size="medium" plain @click="resetHandler">{{
          $t('deviceList.reset')
        }}</el-button>
        <el-button
          v-if="btnShow && btnShow.add"
          type="primary"
          @click="
            visible = true
            currentItem = null
          "
          icon="iconxinzeng"
          >{{ $t('deviceRoom.bindSingleEquipment') }}</el-button
        >
        <el-button type="primary" @click="visible2 = true" icon="iconxinzeng">{{
          $t('deviceRoom.bindBatchEquipment')
        }}</el-button>
        <el-button type="primary" @click="changeCustomers">{{
          $t('deviceRoom.changeCustomers')
        }}</el-button>
      </el-form>
    </div>

    <div class="table-wrap">
      <el-table
        :data="tableData"
        border
        ref="table"
        v-loading="loading"
        height="100%"
        @selection-change="val => (multipleSelection = val)"
      >
        <el-table-column v-if="btnShow && btnShow.delete" type="selection" width="55" />
        <!-- <el-table-column
          min-width="150"
          show-overflow-tooltip
          prop="sn"
          :label="$t('deviceList.sn')"
        /> -->
        <el-table-column min-width="150" show-overflow-tooltip :label="$t('deviceList.sn')">
          <template slot-scope="{ row }">
            <span style="position: relative;top: -3px;">{{ row.sn }}</span>
            <img v-if="row.bindingRelationship == 1" src="@/assets/img/bind.png" class="bindIcon" />
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('deviceList.deviceType')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="spName"
          :label="$t('deviceList.deliveryCustomer')"
        />
        <el-table-column show-overflow-tooltip prop="spId" :label="$t('deviceList.customerId')" />
        <el-table-column show-overflow-tooltip :label="$t('deviceRoom.deliveryTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.warehouseOutTime) }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button type="text" v-if="btnShow && btnShow.edit" @click="editHandler(row)">{{
                $t('edit')
              }}</el-button>

              <el-button type="text" v-if="btnShow && btnShow.delete" @click="delHandler(row)">{{
                $t('delete')
              }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-device-modal
      @getData="replaceData"
      :serviceList="serviceList"
      :groupList="groupList"
      :modelList="modelList"
      :printerList="printerList"
      :scanningEquipmentList="scanningEquipmentList"
      :cameraList="cameraList"
      :electronicScalesList="electronicScalesList"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
    <add-moredevice-modal @getData="replaceData" :visible.sync="visible2" />
    <el-dialog
      v-elDragDialog
      append-to-body
      width="500px"
      :title="$t('tips')"
      :close-on-click-modal="false"
      :visible.sync="changeDialog"
      :destroy-on-close="true"
    >
      <div style="    margin-bottom: 12px;">
        {{ this.$t('deviceRoom.changeNums', { nums: multipleSelection.length }) }}
      </div>
      <div>
        <span style="color: #f23b4c;">*</span>
        {{ $t('serviceManagement.customerName') }}&emsp;<el-select
          v-model="spId"
          :placeholder="$t('deviceModal.selectCustomer')"
        >
          <el-option
            v-for="item in serviceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeDialog = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="confirm">{{ $t('save') }}</el-button>
      </span>
    </el-dialog>
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddDeviceModal from './components/AddDeviceModal'
import AddMoredeviceModal from './components/AddMoredeviceModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddDeviceModal,
    AddMoredeviceModal
  },
  data() {
    return {
      firstIn: true,
      loading: false,
      changeDialog: false,
      serviceList: [],
      groupList: [],
      modelList: [],
      visible: false,
      visible2: false,
      currentItem: {},
      tableData: [],
      printerList: [],
      scanningEquipmentList: [],
      cameraList: [],
      electronicScalesList: [],
      multipleSelection: [],
      spId: ''
    }
  },
  created() {
    this.getByPeripheralType('printer')
    this.getByPeripheralType('scanningEquipment')
    this.getByPeripheralType('camera')
    this.getByPeripheralType('electronicScales')
  },
  methods: {
    resetHandler() {
      this.params = {
        pageSize: 20,
        currentPage: 1
      }

      this.getData()
    },
    changeCustomers() {
      if (!this.multipleSelection.length) {
        return this.$message.warning(this.$t('leastOne'))
      }
      this.changeDialog = true
    },

    async confirm() {
      try {
        this.loading = true
        if (this.spId === '') {
          return this.$message.warning(this.$t('serviceAccountManagement.pleaseSelectCustomer'))
        }
        let ids = this.multipleSelection.map(item => item.id)
        const res = await this.$api.deviceManagement.batchUpdateSpId({
          spId: this.spId,
          ids
        })

        this.$message.success(this.$t('succeeded'))
        this.changeDialog = false
        this.$refs.table && this.$refs.table.clearSelection()
        this.getData()
      } finally {
        this.loading = false
      }
    },
    async getByPeripheralType(type) {
      try {
        const res = await this.$api.deviceManagement.getByPeripheralType({
          peripheralType: type
        })

        this[`${type}List`] = res.result.map(item => ({
          label: item.peripheralName,
          value: String(item.id || '')
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getSelectData() {
      let result = await Promise.all([
        this.$api.systemManagement.spList({ pageSize: -1 }),
        this.$api.deviceManagement.groupList({ pageSize: -1 }),
        this.$api.deviceManagement.modelList({ pageSize: -1 })
      ])

      this.serviceList = result[0].result.list.map(item => {
        return {
          label: item.name,
          value: item.spId
        }
      })

      this.modelList = result[2].result.list.map(item => {
        return {
          label: item.name,
          value: item.id,
          signatureFileName: item.signatureFileName
        }
      })
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      if (this.firstIn) {
        try {
          await this.getSelectData()
        } catch (e) {
          console.log(e)
        } finally {
          this.firstIn = false
          this.renderData()
        }
      } else {
        this.renderData()
      }
    },
    async renderData() {
      try {
        let data = { ...this.params }
        if (data.time && data.time.length) {
          data.warehouseOutStartTime = data.time[0]
          data.warehouseOutEndTime = data.time[1]
          data.time = undefined
        } else {
          data.warehouseOutStartTime = undefined
          data.warehouseOutEndTime = undefined
        }

        if (!data.sn) {
          delete data.sn
        }

        const res = await this.$api.deviceManagement.deviceRoomList(data)

        if (res.result) {
          this.tableData = res.result.list
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async delHandler(row) {
      try {
        await this.$confirm(this.$t('deviceRoom.deleteIssueRecord'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
        this.loading = true

        await this.$api.deviceManagement.deviceRoomDelete({ id: row.id })
        this.$message.success(this.$t('deleteSuccess'))
        this.renderData()
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    editHandler(row) {
      this.currentItem = row
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss"></style>
