<template>
  <page>
    <div slot="headerLeft">
      <el-select
        :placeholder="$t('deviceList.peripheralType')"
        collapse-tags
        clearable
        v-model="params.peripheralType"
      >
        <el-option
          v-for="item in peripheralTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>
    <div slot="headerRight">
      <c-button
        type="primary"
        @click="
          visible = true
          currentItem = null
        "
        icon="iconxinzeng"
        >{{ $t('newadd') }}</c-button
      >
    </div>

    <div class="table-wrap">
      <el-table ref="deviceTypeTable" :data="tableData" border v-loading="loading" height="100%">
        <el-table-column prop="id" :label="$t('index')" />
        <el-table-column prop="peripheralName" :label="$t('deviceList.peripheralName')" />
        <el-table-column prop="peripheralType" :label="$t('deviceList.peripheralType')">
          <template slot-scope="{ row }">
            <span>{{ typeObj[row.peripheralType] }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="$t('peripheralList.isEnable')">
          <template slot-scope="{ row }">
            <el-switch v-model="row.status" @change="statusUpdate(row)" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button type="text" @click="editHandler(row)">{{
                $t('edit')
              }}</el-button>
              <el-button type="text" @click="delHandler(row.id)">{{
                $t('delete')
              }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-modal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      typeObj: {
        printer: this.$t('deviceList.printer'),
        scanningEquipment: this.$t('deviceList.scanningDevice'),
        camera: this.$t('deviceList.camera'),
        electronicScales: this.$t('deviceList.electronicScales')
      },
      peripheralTypeList: [
        {
          label: this.$t('deviceList.printer'),
          value: 'printer'
        },
        {
          label: this.$t('deviceList.scanningDevice'),
          value: 'scanningEquipment'
        },
        {
          label: this.$t('deviceList.camera'),
          value: 'camera'
        },
        {
          label: this.$t('deviceList.electronicScales'),
          value: 'electronicScales'
        }
      ],
      currentItem: {},
      tableData: []
    }
  },
  created() {},
  methods: {
    async statusUpdate(row) {
      this.loading = true

      try {
        await this.$api.deviceManagement.configurationUpdate({
          id: row.id,
          status: row.status ? 0 : 1
        })
      } catch (e) {
        row.status = !row.status
      } finally {
        this.loading = false
      }
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const params = { ...this.params }

        params.peripheralType = params.peripheralType || undefined

        const res = await this.$api.deviceManagement.configurationList(params)

        if (res.result) {
          this.tableData = res.result.list.map(item => {
            item.status = !item.status

            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    editHandler(row) {
      this.currentItem = row
      this.visible = true
    },
    delHandler(id) {
      this.$confirm(this.$t('peripheralList.deletePeripheral'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })
        .then(async () => {
          this.loading = true

          try {
            await this.$api.deviceManagement.configurationDelete({ id })
            this.$message.success(this.$t('deleteSuccess'))
            this.getData(false)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.viewer {
  img {
    width: 50px;
    height: 50px;
  }
}
</style>
