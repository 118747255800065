<template>
  <el-dialog
    v-elDragDialog
    width="500px"
    :title="$t('deviceList.filesTransfer')"
    :close-on-click-modal="false"
    :visible.sync="show"
    :before-close="close"
    class="add-device"
  >
    <el-form
      v-loading="loading"
      :element-loading-text="uploadMessage"
      :model="form"
      size="small"
      ref="form"
      label-width="100px"
    >
      <el-form-item
        :rules="[{ required: true, message: $t('deviceModal.pleaseUploadFile') }]"
        :label="$t('uploadFiles')"
        prop="file"
      >
        <el-upload
          action="null"
          :on-change="file => onFilechange(file, 'file')"
          :on-remove="() => (form.file = '')"
          :show-file-list="true"
          :multiple="false"
          :auto-upload="false"
          ref="fileUpload"
        >
          <el-button @click="$refs.fileUpload.clearFiles()" type="primary">{{$t('deviceModal.selectFile')}}</el-button>
        </el-upload>
      </el-form-item>

      <p class="tips">{{$t('deviceModal.fileDescription')}}</p>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">{{$t('cancel')}}</el-button>
      <el-button :disabled="loading === true" type="primary" @click="submitForm(form)"
        >{{$t('save')}}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import axios from 'axios'
import { PLATFORM } from '@/lib/constant'
export default {
  mixins: [dialogCommonParams],
  props: ['groupIds'],
  components: {},
  data() {
    return {
      loading: false,
      uploadMessage: 0,
      form: {
        file: ''
      }
    }
  },
  created() {},
  methods: {
    close() {
      this.show = false
      this.uploadMessage = 0
      if (this.cancels) {
        this.cancels()
      }
    },

    onFilechange(file, key) {
      const limit = file.size / 1024 / 1024 < 100

      if (!limit) {
        this.$message({
          message: this.$t('deviceModal.fileSize'),
          type: 'warning'
        })
        this.$refs.fileUpload.clearFiles()
        return
      }

      this.form[key] = file.raw
    },

    submitForm(data) {
      const CancelToken = axios.CancelToken
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          const formData = new FormData()

          for (const i in this.form) {
            this.form[i] && formData.append(i, this.form[i])
          }

          if (this.groupIds) {
            formData.append('groupIds', this.groupIds.join(','))
          } else {
            formData.append('deviceSn', this.currentItem.sn)
          }

          try {
            const apiType = PLATFORM === 'admin' ? '/admin/api' : '/sp/api'

            let url = `/scpserver${apiType}/publish/fileTransfer`

            if (process.env.NODE_ENV === 'development') {
              url = url.replace('/scpserver', '')
            }

            const res = await axios.post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                system: PLATFORM === 'admin' ? 4 : 5
              },
              timeout: 20000000,
              cancelToken: new CancelToken(c => {
                this.cancels = c
              }),
              onUploadProgress: progressEvent => {
                if (progressEvent && progressEvent.loaded) {
                  let complete = (((progressEvent.loaded / progressEvent.total) * 100) | 0) + '%'
                  this.uploadMessage = complete
                }
              }
            })

            if (res.data.code !== 'OK') {
              this.$message({
                message: res.data.summary || this.$t('systemError'), 
                type: 'error',
                duration: 3 * 1000
              })
            } else {
              this.$message.success(this.$t('deviceModal.transferSuccess'))
              this.show = false
            }
          } catch (e) {
            console.log(e)
            this.$message({
              message: res.data.summary || this.$t('systemError'),
              type: 'error',
              duration: 3 * 1000
            })
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner i {
  font-size: 30px;
}

::v-deep .el-loading-spinner .el-loading-text {
  color: #85d71a;
}

.tips {
  font-size: 13px;
  color: #666;
  margin-left: 30px;
  margin-bottom: 20px;
}
</style>
